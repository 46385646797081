import Link from 'next/link';
import { useRouter } from 'next/router';

type Tab = {
  text: string;
  link: string;
};

type BridgeContainerProps = {
  tabs?: Tab[];
  children: JSX.Element;
};

export function BridgeContainer({ children, tabs }: BridgeContainerProps) {
  const { pathname } = useRouter();
  return (
    <div className="m-0 flex h-full w-full flex-col p-0">
      <div className="flex flex-row sm:border-b sm:border-white/10 sm:px-8">
        {tabs?.map(({ text, link }) => (
          <Link
            key={`container-link-${link}`}
            href={link}
            className={`text-md mr-4 w-1/2 pb-2 text-center font-sans sm:w-auto sm:text-sm ${
              pathname.includes(link)
                ? 'border-primary border-b-[3px] uppercase'
                : 'uppercase text-white/50'
            }`}
          >
            {text}
          </Link>
        ))}
      </div>
      {children}
    </div>
  );
}
