import { QuestionAccordion } from '@/components/Faq/QuestionAccordion';

export function FaqSidebar() {
  return (
    <div className="flex h-full flex-shrink-0 flex-col py-4 font-sans md:sticky md:top-0 lg:w-[300px] lg:border-l lg:border-white/10 xl:w-[420px]">
      <span className="mb-8 ml-4 font-mono uppercase text-stone-500 text-white/50">
        Frequently asked
      </span>

      <QuestionAccordion
        question="What is Redstone?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              Redstone is an{' '}
              <a
                href="https://ethereum.org/developers/docs/scaling/optimistic-rollups/"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                Optimistic Rollup
              </a>{' '}
              building on the{' '}
              <a
                href="https://stack.optimism.io/"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                OP Stack
              </a>{' '}
              codebase. In practical terms, this means it is an Ethereum blockchain that inherits
              most security guarantees from L1 (Ethereum mainnet), but has much cheaper
              transactions. This is important because L1 transactions are typically too expensive
              for many use cases, such as games.
            </p>
            <p>
              The main difference between Redstone and{' '}
              <a
                href="https://stack.optimism.io"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                other OP Stack rollups
              </a>{' '}
              is data availability. Instead of using{' '}
              <a
                href="https://docs.optimism.io/landscape#ethereum-da"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                L1 Ethereum for data availability (to post transactions)
              </a>
              , Redstone rollups use{' '}
              <a
                href="https://specs.optimism.io/experimental/plasma.html"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                Plasma mode
              </a>
              , and just post input commitments to L1. This lets Redstone transactions be a lot
              cheaper.
            </p>
            <p>
              The tradeoff is that while data integrity is guaranteed by L1 Ethereum, data
              availability has to be guaranteed by other means. Redstone uses a{' '}
              <a
                href="/docs/what-is-redstone"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                challenge mechanism
              </a>{' '}
              to allow anyone to challenge the availability of the data corresponding to a given
              commitment on L1. You can inspect the commitment data and challenge commitments on the{' '}
              <a
                href="/data-availability"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                DA Explorer
              </a>{' '}
            </p>
          </div>
        }
      />

      <QuestionAccordion
        question="What is a bridge?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              Just as a physical bridge connects two physical locations, a blockchain bridge
              connects two blockchains. In this case, the bridge allows you to transfer assets
              between the L1 Ethereum mainnet and Redstone, or between Holesky and Garnet (our
              testnet).
            </p>
            <p>
              For more information, see{' '}
              <a
                href="https://ethereum.org/en/developers/docs/bridges/"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                ethereum.org
              </a>
              .{' '}
            </p>
          </div>
        }
      />

      <QuestionAccordion
        question="What are the Redstone bridges?"
        answer="The Redstone bridge enables the transfer of ETH and other tokens between the L1 (Ethereum) and L2 (Redstone)."
      />

      <QuestionAccordion
        question="Can I still use the bridge on redstone.xyz?"
        answer="No, the bridge on redstone.xyz has been deprecated. Please use a third-party bridge like Relay, Superbridge or Brid.gg."
      />

      <QuestionAccordion
        question="I used redstone.xyz bridge in the past, how do I find my deposit or withdrawal?"
        answer="Navigate to one of the third-party bridging providers to look up your transaction."
      />

      <QuestionAccordion
        question="What if I have a question or problem?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              You are invited to the{' '}
              <a
                href="https://lattice.xyz/discord"
                className="text-primary font-semibold opacity-90 brightness-125 hover:underline"
              >
                Lattice Discord
              </a>
              , where the #redstone-help channel is available to answer your questions.{' '}
            </p>
          </div>
        }
      />
    </div>
  );
}
